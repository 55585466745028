<template>
  <base-layout>
    <VehicleRentalIndex :fst-id="`vehicle-rentals`" :pageTitle="$t('components.vehicleRentalsManagement.headline')" />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import VehicleRentalIndex from '@/composites/vehicle-rental/index/Index.vue'

export default {
  name: 'VehicleRentals',
  components: {
    BaseLayout,
    VehicleRentalIndex,
  },

  methods: {},
}
</script>

<style lang="scss">
@media only screen and (max-width: 500px) {
  .filteroptions {
    margin-top: 10px;
    margin-left: 0 !important;
  }
}
</style>
